import React, { useEffect, useState } from 'react';
import deepai from 'deepai';
import Diver from '../img/Divers.jpg';

//const key = '295c516b-3b0c-4616-a42d-acc0e0bd1440';
//const text = 'face of diver under the water with blue background and futuristic fishes and buildings ';

//deepai.setApiKey(key);

export const Main = () => {
    const [image, setImage] = useState(null);

    /*const generateImage = async () => {
        const resp = await deepai.callStandardApi("cyberpunk-portrait-generator", {
            text: text,
        });
        setImage(resp.output_url);
        localStorage.setItem("deepaiImage", resp.output_url);
    };*/

    useEffect(() => {
        /*if (!image) {
            const deepAiImage = localStorage.getItem("deepaiImage");

            if (deepAiImage) {
                setImage(deepAiImage)
            } else {
                generateImage();
            }
        }*/
    }, []);

    return (<div>
        <header className="App-header">
            <button onClick={() => null /*generateImage*/}>Regenerate</button>
            {image && <p>
                Success! The writtier.com server block is working!
            </p>}
            {
                !image && (<img src={Diver} /> )
            }
        </header>
    </div>)
};
